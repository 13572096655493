<template>
  <data-validation
    has-fill-record
    :title="$t('reservation.data_validation_access')"
    :description="$t('reservation.data_validation_access_description')"
    :auth="auth"
    :contacts="contacts"
    :is-loading="isLoading"
    :is-loading-new="isLoadingOrders"
    @change="changeAuth({ validationMethod: $event })"
    @click:fnrh="onClickNewFnrh"
    @send="sendGuestPin"
    @back="$router.push({ name: 'home' })"
  />
</template>

<script>
import DataValidation from "./components/DataValidation.vue"
import { useDataValidation } from "./useDataValidation"

export default {
  components: { DataValidation },
  setup() {
    return useDataValidation()
  },
}
</script>
